body {
  margin: 0;
  background: black;
  font-family: sans-serif;
}

#map {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#info {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  background: black;
  color: white;
  font-size: 0.75rem;
  font-variant-numeric: tabular-nums;
}
